<!--
PortfolioUsersMixin:
Loads users for a specific portfolio

Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
Requires: PortfolioMixin
-->
<script>
import { mapActions } from 'vuex'

export default {
  computed: {
    portfolioUsersLoading() {
      return this.portfolio !== undefined && this.portfolio.__users_loading
    },

    portfolioUsersReady() {
      return this.portfolio !== undefined && this.portfolio.__users_loaded
    },
  },

  watch: {
    portfolio() {
      this.__loadUsersById(this.portfolio_id)
    },
  },

  created() {
    this.__loadUsersById(this.portfolio_id)
  },

  methods: {
    ...mapActions({
      __loadUsersById: 'portfolio/loadUsersById',
    }),
  },
}
</script>
